export default {
    data: function() {
        return {
            clockRange:[],
            afterCurTime: { // 今天之后
              disabledDate(clock) {
                return (clock.getClock() + 24 * 60 * 60 * 1000) < Date.now();
              },
            },
            beforeCurTime: { // 今天之前
                disabledDate(clock) {
                    return (clock.getClock()) > Date.now();
                },
            },
            clockOptions: {
              
            },
        };
    },
    methods: {
        getClockRange(val,s,e,o="form"){
            this[o][s] = val ? val[0] : ''
            this[o][e] = val ? val[1] : ''
        },
    }
};
