<template>
  <div class="wrap">
      <!-- 新增广告 -->
      <div class="title">
        <span class="titlespan">{{isEditor?'编辑':'新增'}}广告</span> 
        <el-button class="fr" size="small" @click="$router.push('/advertising')">返回</el-button>
      </div>
      <el-tabs v-model="active" @tab-click="handleClick">
        <el-tab-pane label="图片" name="1"></el-tab-pane>
        <el-tab-pane label="视频" name="2"></el-tab-pane>
        <el-tab-pane label="红包" name="3"></el-tab-pane>
        <el-tab-pane label="动态植入" name="4"></el-tab-pane>
        <el-tab-pane label="代码引用" name="5"></el-tab-pane>
      </el-tabs>
      <div class="formbox">
        <el-form ref="form" :model="form"  label-width="110px" :rules="formRules">
          <div class="box-tit">区域选择</div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="投放区域：">
                <area-tree ref="areaTree" size='middle' :disabled="launching" @setQueryParams="handleQuery" class="year" @clear="clearArea"></area-tree>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="box-tit">目标群体</div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="年级：" prop="groupYear" >
                <el-select :disabled="launching" v-model="form.groupYear" placeholder="请选择年级">
                  <el-option v-for="item in yearOptions" :key="item" :label="item+'级'" :value="item+'级'"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="专业：" prop="groupMajor">
                <el-select :disabled="launching" v-model="form.groupMajor" filterable placeholder="请选择专业">
                  <el-option v-for="item in majorOptions" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <div class="box-tit">广告配置</div>
            <el-form-item label="广告位置：" prop="posId">
              <el-select :disabled="launching" v-model="form.posId" placeholder="请选择广告位置" style="width:30%">
                <el-option v-for="item in advertLocal" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="广告标题：" prop="advTitle">
              <el-input :disabled="launching" placeholder="请输入广告标题" v-model="form.advTitle" style="width:60%"></el-input>
            </el-form-item>
            <el-form-item label="广告描述：" prop="advInfo">
              <el-input :disabled="launching" placeholder="请输入广告描述" v-model="form.advInfo" style="width:60%"></el-input>
            </el-form-item>
            <el-form-item label="广告内容：" v-if="active=='1' || active=='2' || active=='3'" prop="srcType">
              <el-radio-group :disabled="launching" v-model="form.srcType">
                <el-radio :label="1">本地上传</el-radio>
                <el-radio :label="2">添加链接</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="广告内容：" v-if="active=='4'|| active=='5'">
              <el-input :disabled="launching" type="textarea" :rows="6"  v-model="form.advCode" placeholder="请输入广告内容" style="width:60%">
              </el-input>
              <!-- <el-button v-if="active=='5'">预览</el-button> -->
              <base-upload v-if="active=='4'" v-model="form.advSrc" fileType="file" tips="" :limit="1" @beforeUpload="handleBeforeUpload" :uploadData="uploadData"></base-upload>
            </el-form-item>
            <el-form-item v-if="active=='1' && form.srcType=='1'" prop="advSrc">
              <base-upload v-model="form.advSrc" fileType="file" tips="" :limit="1" @beforeUpload="handleBeforeUpload" :uploadData="uploadData"></base-upload>
            </el-form-item>
            <el-form-item v-if="(active=='1' || active=='2' || active=='3') && form.srcType=='2'" prop="advLnk">
              <el-input :disabled="launching" :rows="6"  v-model="form.advLnk" placeholder="请输入广告链接" style="width:60%"></el-input>
            </el-form-item>
            <el-form-item v-if="(active=='2' || active=='3')&& form.srcType=='1'" prop="thumb">
              <base-upload :disabled="launching" tips="仅支持avi,flv,mp4,3gp,AVI,FLV,MP4,3GP格式" v-model="form.advSrc" fileType="file" :uploadData="uploadData" buttonTxt="上传视频" :limit="1" @beforeUpload="handleBeforeUpload3"></base-upload>
            </el-form-item>
            <el-row>
              <el-col :span="8">
                <el-form-item label="投放公司：" prop="organ">
                  <el-input :disabled="launching" v-model="form.organ" placeholder="请输入公司名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="投放日期：" prop="timeRange">
                  <el-date-picker :disabled="launching" style="width:316px;" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate')" type="datetimerange" value-format="yyyy-MM-dd HH:mm" :picker-options="recentOptions" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="时间段：" prop="clockRange">
                  <el-time-picker style="width:316px;" is-range :disabled="launching"
                    v-model="clockRange" :picker-options="clockOptions" value-format="HH:mm" 
                    @change="getClockRange($event,'startClock','endClock')"
                    range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" v-if="active!='4' && active!='1'">
                <el-form-item label="播放时长：" prop="bfsc">
                  <el-input :disabled="launching" v-model.number="form.bfsc" placeholder="请输入播放时长">
                    <template slot="append">秒</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="active=='3'" >
                <el-form-item label="红包总个数：" prop="totalNum">
                  <el-input :disabled="launching" v-model="form.totalNum" placeholder="请输入红包总个数">
                    <template slot="append">个</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="active=='3'">
                <el-form-item label="红包总金额：" prop="totalMoney">
                  <el-input :disabled="launching" v-model="form.totalMoney" placeholder="请输入红包总金额">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="active=='3'">
                <el-form-item label="最大金额：" prop="maxMoney">
                  <el-input :disabled="launching" v-model.number="form.maxMoney" placeholder="请输入最大金额">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="计费方式：" prop="cost">
                  <el-select :disabled="launching" v-model="form.chargeType" clearable filterable placeholder="选择方式" style="width:35%;marginRight:5%;">
                    <el-option v-for="item in chargeTypeArr" :key="item.status" :label="item.label" :value="item.status" ></el-option>
                  </el-select>
                  <el-input :disabled="launching" placeholder="请输入金额" v-model.number="form.cost" style="width:60%" >
                    <template slot="append" v-if="form.chargeType==1">元/年</template>
                    <template slot="append" v-else-if="form.chargeType==2">元/月</template>
                    <template slot="append" v-else-if="form.chargeType==3">元/千次</template>
                    <template slot="append" v-else-if="form.chargeType==4">元/互动</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="广告排序：" prop="advSort">
                  <el-input :disabled="launching" v-model.number="form.advSort" placeholder="请输入排序               值越大越靠前"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
               <el-col :span="8">
                <el-form-item label="广告状态：">
                  <el-radio-group v-model="form.advStatus">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="有无报名：" prop="enroll">
                  <el-radio-group :disabled="launching" v-model="form.enroll">
                    <el-radio :label="1">有</el-radio>
                    <el-radio :label="0">无</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="enrollUrl" label-width="0" v-if="form.enroll==1">
                  <el-input :disabled="launching" v-model="form.enrollUrl" placeholder="请输入链接" style="width:100%;margin-left:10px;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="footer">
                <el-button @click="$router.push({path:'/advertising'}),resetForm()">取消</el-button>
                <el-button type="primary" @click="onSubmit" @click.native="(refresh)">确定</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>    
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import datePicker from "@/mixins/datePicker";
import clockPicker from "@/mixins/clockPicker";
import BaseUpload from "@/components/common/BaseUpload";
import AreaTree from "@/components/common/AreaTree";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'newPart',
  components: {
    PagedList,
    BaseUpload,
    AreaTree
  },
  mixins: [ pageMixin, datePicker, clockPicker ],
  data() {
    return{
      advertLocal:[],
      majorOptions: [],
      yearOptions:[],
      uploadData:{
        bizType:'',
        bizId:'',
        needStore:'1',
      },
      isEditor: false,
      // 投放中
      launching: false,
      clockRange:'',
      value2:'',
      queryParams:{},
      active:'1',
      chargeTypeArr: [
        { status: 1, label: "包年" },
        { status: 2, label: "包月" },
        { status: 3, label: "按千次" },
        { status: 4, label: "互动" },
      ],
      form: {
        zoneType: "0",
        zoneId: "",
        schoolId: "",
        groupYear:'',
        // groupFade: '',
        groupMajor: '',
        posId: null,
        advType: '0',
        advTitle: '',
        advInfo: '',
        srcType: '',
        advSrc: '',
        bonusFlag: '',
        thumb: '',
        advLnk: '',
        advCode:'',
        bfsc: '',
        totalNum: '',
        totalMoney: '',
        maxMoney: '',
        couponMoney: '',
        organ: '',
        startDate: '' ,
        endDate: '',
        startClock: '',
        endClock: '',
        chargeType: '',
        chargeRule: '',
        advSort: '',
        advStatus: '',
        enroll: '',
        enrollNum: '',
        h5url:'',
        enrollUrl: '',
        cost: null,
      },
      zoneInfo: {
        province: null,
        area: null,
        city: null
      },
      formRules: {
        groupYear: [{ required: true, message: '请选择年级', trigger: 'blur' }],
        // groupFade: [{ required: true, message: '请选择院系', trigger: 'blur' }],
        groupMajor: [{ required: true, message: '请选择专业', trigger: 'blur' }],
        posId: [{ required: true, message: '请选择广告位置', trigger: 'blur' }],
        advTitle: [{ required: true, message: '请输入广告标题', trigger: 'blur' }],
        advInfo: [{ required: true, message: '请输入广告描述', trigger: 'blur' }],
        advCode: [{ required: true, message: '请输入广告内容', trigger: 'blur' }],
        advLnk: [{ required: true, message: '请输入广告链接', trigger: 'blur' }],
        organ: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        bfsc: [{ required: true, message: '请输入播放时长', trigger: 'blur' }],
        totalNum: [{ required: true, message: '请输入红包总个数', trigger: 'blur' }],
        totalMoney: [{ required: true, message: '请输入红包总金额', trigger: 'blur' }],
        maxMoney: [{ required: true, message: '请输入最大金额', trigger: 'blur' }],
        cost: [{ required: true, message: '请选择计费方式并输入金额', trigger: 'blur' }],
        advSort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        enrollUrl: [{ required: true, message: '请输入链接', trigger: 'blur' }],
      },
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('advertising',["advertGetPosition", "advertAdd", "advertEdit", "advertLoad", "selectAllMajor"]),
    handleQuery(data) {
      this.clearArea()
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.zoneInfo.province = ele }
        if ((ele + "").length === 12) { this.zoneInfo.city = ele }
        if ((ele + "").length === 15) { this.zoneInfo.area = ele }
        if ((ele + "").length < 9) { this.zoneInfo.schoolId = ele}
        if(!this.zoneInfo.province) { this.form.zoneType = '0' }
        else if(!!this.zoneInfo.province && !this.zoneInfo.city){ this.form.zoneId = this.zoneInfo.province
         this.form.zoneType = '1' }
        else if(!!this.zoneInfo.city && !this.zoneInfo.area){ this.form.zoneId = this.zoneInfo.city 
         this.form.zoneType = '2'}
        else if(!!this.zoneInfo.area && !this.zoneInfo.schoolId){ this.form.zoneId = this.zoneInfo.area
        this.form.zoneType = '3' }
        else if(!!this.zoneInfo.schoolId){ this.form.schoolId = this.zoneInfo.schoolId
        this.form.zoneType = '4', this.form.zoneId = null }
      });
    },
    clearArea(){
      this.form.zoneId = null
      this.form.schoolId = null
      this.zoneInfo.province = null;
      this.zoneInfo.city = null;
      this.zoneInfo.area = null;
    },
    timeRangeChange(val, index){
      this.gameTime[index].startDate = val ? val[0] : ''
      this.gameTime[index].endDate = val ? val[1] : ''
    },
    clockRangeChange(val, index){
      this.gameClock[index].startClock = val ? val[0] : ''
      this.gameClock[index].endClock = val ? val[1] : ''
    },
    clockFocus(ele) {
      console.log(ele.valueOnOpen)
    },
    // 文件上传
    handleBeforeUpload(file, callback) {
      const isPicture =file.name.includes('xls') || file.name.includes('ppt') || file.name.includes('doc') || file.name.includes('pdf') || file.name.includes('jpg') || file.name.includes('png');
			if (!isPicture) {
          this.$message.error('请上传正确的文件格式!');
      }
		  callback(isPicture)
    },
    // 视屏上传
    handleBeforeUpload3(file, callback) {
      // avi,flv,mp4,3gp,AVI,FLV,MP4,3GP
      const isPicture = file.name.includes('avi') || file.name.includes('flv') || file.name.includes('mp4') || file.name.includes('3gp') || file.name.includes('AVI') || file.name.includes('FLV') || file.name.includes('MP4') || file.name.includes('3GP');
      const isLt2M = file.size / 1024 / 1024 < 500;
			if (!isPicture) { this.$message.error('请上传正确的文件格式!'); }
			if (!isLt2M) { this.$message.error('视频大小不能超过500MB!'); }
		  callback(isPicture)
    },
    resetForm(){
      this.clearArea()
      this.$refs.areaTree.resetValue()
      // this.$refs.form.resetFields();
      // this.form.srcType = ""
      // this.form.chargeType =''
      // this.form.advStatus =''
      // this.form.enroll =''
      // this.form.zoneType = '0'
      this.resetformData()
    },
    async handleClick(tab, event) {
      this.form.posId = null
      this.resetForm()
      try{
        this.advertLocal = await this.advertGetPosition({type: tab.name})
      }catch(error){}
    },
    onSubmit() {
      this.radioWrite()
      let params = Object.assign({},this.form)
      if(params.extend){ delete params.extend, delete params.addTime, delete params.updTime, delete params.addUserId, delete params.tfsc, delete params.timeLast, delete params.updUserId  }
      params.advType = this.active
      for(let k in params){
        if(params[k] !== 0 && !params[k]){
          delete params[k]
        }
      }
      console.log(params)
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if(!this.isEditor){
            try{
              let res = await this.advertAdd(params)
              if(res!="系统出错"){ this.$message.success("新增广告成功") }
            }catch(error){}
          }else{
            try{
              let res = await this.advertEdit(params)
              if(res!="系统出错"){ this.$message.success("修改广告成功") }
            }catch(error){}
          }
          this.$router.push({
            path:'/advertising'
          })
          this.resetForm()
        } else {
          return false;
        }
      });
    },
    // 判断单选
    radioWrite() {
      if(this.form.srcType !='1'){ this.form.advSrc ='' }else{ this.form.advLnk = '' }
      if(this.form.enroll !='1'){ this.form.enrollUrl ='' }
    },
    resetformData() {
      this.clockRange =''
      this.timeRange = []
      this.form = {zoneType: "0", zoneId: null, schoolId: "", groupYear:'',
        groupMajor: '', posId: null, advType: 0, advTitle: '', advInfo: '', srcType: '',
        advSrc: '', bonusFlag: '', thumb: '', advLnk: '', bfsc: '', totalNum: '', totalMoney: '',
        maxMoney: '', couponMoney: '', organ: '', startDate: '' , endDate: '', startClock: '',
        endClock: '', chargeType: '', chargeRule: '', advSort: '', advStatus: '', enroll: '',
        enrollNum: '', h5url:'', enrollUrl: '', cost: null,}
    },
    refreshNow() {
      this.$router.go(0)
    },
    async handler(n){
      if(Object.keys(this.$route.query).length !=0 ) {
        console.log(this.$route.query.id)
        let detail_info = await this.advertLoad ({id: this.$route.query.id})
        if(this.$route.query.active == 3) { this.launching = true
        }else { this.launching = false }
        this.isEditor = true
        this.active = detail_info.advType+'' 
        this.advertLocal = await this.advertGetPosition({type: this.active})
        this.clockRange = [ detail_info.startClock, detail_info.endClock ]
        this.timeRange = [ detail_info.startDate, detail_info.endDate ]
        this.form = detail_info
      } else {
        if(this.$route.query.active == 3) { this.launching = true
        }else { this.launching = false }
        this.isEditor = false
        this.resetformData()
      }
      console.log(this.form)
    },
  },
  created() {
  },
  async mounted() {
    if(this.active == '1') {
      this.form.posId = null
      try{this.advertLocal = await this.advertGetPosition({type: "1"})
      }catch(error){}
    }
    try{this.majorOptions = await this.selectAllMajor({pid: 104006})
    }catch(error){}
    // 获取年级下拉框
    let myDate = new Date().getFullYear()
    this.yearOptions= []
    for(let i = 0;i<5;i++){
      this.yearOptions.push(myDate)
      myDate--
    }
    console.log(this.yearOptions)
  },
  activated(){
    this.handler()
  },

  // watch: {
  //   $route:{
  //     async handler(n){
  //       if(Object.keys(this.$route.query).length !=0 ) {
  //         console.log(this.$route.query.id)
  //         let detail_info = await this.advertLoad ({id: this.$route.query.id})
  //         if(this.$route.query.active == 3) { this.launching = true
  //         }else { this.launching = false }
  //         this.isEditor = true
  //         this.active = detail_info.advType+'' 
  //         this.advertLocal = await this.advertGetPosition({type: this.active})
  //         this.clockRange = [ detail_info.startClock, detail_info.endClock ]
  //         this.timeRange = [ detail_info.startDate, detail_info.endDate ]
  //         this.form = detail_info
  //       } else {
  //         if(this.$route.query.active == 3) { this.launching = true
  //         }else { this.launching = false }
  //         this.isEditor = false
  //         this.resetformData()
  //       }
  //       console.log(this.form)
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // }
}
</script>

<style lang="scss" >
@import "@/assets/scss/common.scss";
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; min-height: 100%;background: #fff;padding:20px;
  .title{ height: 30px; line-height: 30px; border-bottom:1px solid $c-border;
    .titlespan{position:relative;border-bottom:4px solid $c-success;padding-bottom:5px;font-size:16px;font-weight: 600;
    }
  }
  .formbox{background: #fff;
    .box-tit{ font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: bold;color: $c-2;line-height: 20px; margin-bottom: 20px;}
  }
  .footer{ width: 100%;text-align: center;display:block;
    .el-button{width: 140px;margin-right: 20px;height: 50px;}
  }
}
</style>
